<template>
	<div v-if="screen == 0">
		<my-Header></my-Header>
		<router-view></router-view>
		<my-Footer></my-Footer>
	</div>
</template>
<script>
	import myHeader from './components/header.vue'
	import myFooter from './components/footer.vue'
	import './assets/font/font.css'
	import sacleBox from './components/SacleBox.vue'
	export default {
		name: 'app',
		components: {
			myHeader,
			myFooter,
			sacleBox
		},
		data() {
			return {
				login: '',
				register: false,
				forget: false,
				screen: 0,
			}
		},
		created() {

		},
		mounted() {
			if (localStorage.getItem('screen')) {
				this.screen = localStorage.getItem('screen')
			}
		},
	}
</script>
<style>
	.box {
		position: relative;
		z-index: 1;
	}

	.qjimgbox {
		position: absolute;
		bottom: 235px;
		left: 0;
		width: 100%;
		z-index: 0;
	}

	.qjimg {
		width: 100%;
		max-width: 1920px;
		margin: auto;
		display: block;
	}

	#app,
	html,
	body {
		margin: 0;
		padding: 0;
		background: #fff;
		position: relative;
	}

	.router-link-active {
		text-decoration: none;
		color: #fff;
	}

	.router-link,
	a {
		text-decoration: none;
		color: #fff;
	}

	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	input[type="number"] {
		-moz-appearance: textfield;
	}

	.stay span {
		color: #1890FF;
		margin-left: 5px;
	}

	.stay {
		width: 1200px;
		margin: auto;
		margin-bottom: 17px;
		color: #333333;
		font-size: 12px;
	}

	input,
	textarea {
		outline: none;
	}

	.bg {
		width: 100%;
		background: linear-gradient(180deg, #EFFAFF 0%, #FAF6FB 100%);
	}

	.w1200 {
		width: 1200px;
		margin: auto;
	}
</style>