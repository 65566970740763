<template>
	<div class="headbox">
		<div class="headtop">
			<!-- 欢迎语 -->
			<div class="headtitle">欢迎来到诸城市妇幼保健院官网</div>
			<!-- logo -->
			<div class="headlogo">
				<img src="../assets/images/fy/logo.png" />
				<div>诸城市妇幼保健院</div>
			</div>
			<!-- 导航栏 -->
			<div class="el-menu-demo-div">
				<el-menu :default-active="activeIndex2" class="el-menu-demo" mode="horizontal" background-color="#fff"
					text-color="#000" router :ellipsis='false' :collapse="false">
					<el-menu-item @click.native="closeScreen" index="/">首页</el-menu-item>
					<div class="shuline"></div>
					<el-sub-menu index="2" :popper-offset='0' popper-class="clbun" @click.native="routingJump">
						<template #title>医院概况</template>
						<el-menu-item @click.native="closeScreen" index="/introduce" class="gx">医院简介</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/history" class="gx">历史沿革</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/culture" class="gx">医院文化</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/glory" class="gx">医院荣誉</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/show" class="gx">院区展示</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/device" class="gx">先进设备</el-menu-item>
					</el-sub-menu>
					<div class="shuline"></div>
					<el-sub-menu index="3" :popper-offset='0'>
						<template #title>新闻动态</template>
						<el-menu-item @click.native="closeScreen" index="/newList" class="gx">医院新闻</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/noticeList" class="gx">通知公告</el-menu-item>
					</el-sub-menu>
					<div class="shuline"></div>
					<el-sub-menu index="4" :popper-offset='0'>
						<template #title>就医指南</template>
						<el-menu-item @click.native="closeScreen" index="/pingmian" class="gx">院平面图</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/yuyue" class="gx">预约挂号</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/jiuyi" class="gx">就医须知</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/yibao" class="gx">医保指南</el-menu-item>
					</el-sub-menu>
					<div class="shuline"></div>
					<el-sub-menu index="5" :popper-offset='0'>
						<template #title>科室介绍</template>
						<el-menu-item @click.native="closeScreen" index="/daohang" class="gx">科室导航</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/kexue" class="gx">重点学科</el-menu-item>
					</el-sub-menu>
					<div class="shuline"></div>
					<el-sub-menu index="6" :popper-offset='0'>
						<template #title>信息公开</template>
						<el-menu-item @click.native="closeScreen" index="/yuanwu" class="gx">院务公开</el-menu-item>
						<div class="wieth"></div>
						<el-menu-item @click.native="closeScreen" index="/about" class="gx">联系我们</el-menu-item>
					</el-sub-menu>
				</el-menu>
			</div>
		</div>

		<div class="banner">
			<div class="swiper-container bannerSwiper">
				<div class="swiper-wrapper">
					<div class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
<!--						<a :href="item.link_url">-->
						<a>
              <img :src="item.pic ? item.pic : defaultImg"/>
						</a>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		data() {
			return {
				selectClassEnd: '供应', //选择内容
        defaultImg: require('../assets/images/fy/sb.png'),
        bannerList: [],
			}
		},

		mounted() {},

		created() {
      this.getBannerList();
    },

		updated() {},

		methods: {
      routingJump() {
        this.$router.push('/overview');
      },
			closeScreen() {
				localStorage.removeItem('screen')
			},
      getBannerList() {
        this.$apiFun.bannerList({}).then(res => {
          this.bannerList = res.data;
        })
      },
		}
	}
</script>

<style>
	.headbox {
		width: 1900px;
		max-width: 1920px;
		margin: auto;
	}

	.headtop {
		width: 1900px;
		height: 203px;
		background: url('../assets/images/fy/head.png') no-repeat;
		background-size: 100% 100%;
		position: relative;
		position: relative;
		z-index: 1200;
	}

	.banner {
		position: relative;
		z-index: 500;
		max-width: 1920px;
		height: 537px;
		margin: auto;
		margin-top: -72px;
	}

	.el-menu--horizontal {
		z-index: 100 !important;
		border: 0 !important;
	}

	.el-menu--horizontal>.el-menu-item.is-active {
		color: rgba(255, 255, 255, 1) !important;
	}

	.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
	.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
		color: #000 !important;
		font-weight: bold;
		border: 0 !important;
		background-color: #DFF6FF !important;
	}

	.wieth {
		width: 100px;
		height: 1px;
		background-color: #fff;
		margin: auto;
		display: block;
	}

	.is-opened,
	.menu:hover,
	.el-menu--horizontal>.el-sub-menu .el-sub-menu__title:hover {
		background-color: #DFF6FF !important;
	}

	.el-menu-item.is-active,
	.el-menu--horizontal>.el-menu-item.is-active,
	.el-menu--horizontal>.el-sub-menu.is-active .el-sub-menu__title {
		color: #000 !important;
		font-weight: bold;
		border: 0 !important;
		background-color: #DFF6FF !important;
	}

	.gx:hover {
		background-color: #DFF6FF !important;
	}

	.el-popper.is-light {
		color: #333 !important;
		border-bottom: 1px solid #fff !important;
	}

	a {
		color: #333 !important;
	}

	.gx,
	.el-popper.is-light,
	.el-menu--popup {
		border: 0 !important;
		/* margin-left: -19px!important; */
		background-color: #F3F8FF !important;
	}

	.el-menu--popup {
		min-width: 0px !important;
		box-shadow: none !important;
		border-radius: 0 !important;
	}

	.el-menu--horizontal,
	.el-menu--popup,
	.el-menu--popup>.el-menu-item,
	.el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
		width: 133px;
		justify-content: center;
		border: 0;
	}

	.el-menu--popup {
		min-width: 133px;
	}

	.el-sub-menu__icon-arrow {
		display: none !important;
		border: 0 !important;
	}

	.el-menu-item,
	.el-sub-menu {
		width: 133px;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9999;
	}

	.el-menu-demo {
		width: 100%;
		max-width: 720px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.shuline {
		width: 0.5px;
		height: 20px;
		background-color: #fff;
		border-radius: 4px;
		padding: 0.5px;
	}

	.flheader img {
		width: 48px;
		height: 48px;
		border-radius: 48px;
	}

	.flheader {
		width: 56px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
		/* background: #1890FF; */
	}

	.headtitle {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
		line-height: 20px;
		position: absolute;
		top: 11px;
		left: 583px;
	}

	.headlogo {
		font-size: 20px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #333333;
		line-height: 28px;
		position: absolute;
		top: 26px;
		left: 385px;
		display: flex;
		flex-direction: column;
		align-items: center;
		white-space: nowrap;
	}

	.headlogo img {
		width: 115px;
		height: 115px;
	}

	.el-menu-demo-div {
		position: absolute;
		top: 60px;
		right: 400px;
	}

  .swiper-slide > a > img {
    width: 1900px;
    height: 537px;
  }
</style>
