<template>
  <div class="box">
    <div class="fotnav">
      <div class="fotinfo">
        <div class="">
          <div class="fotlist">
            <div class="fotlist-n" v-for="(item, index) in list" @click="toInfo(item.url)">
              {{ item.name }}
            </div>
          </div>
          <div>全国服务热线：{{ aboutUs.mobile }} 地址：{{ aboutUs.address }}</div>
        </div>
        <div class="ewm">
<!--          <img src="../assets/images/fy/ewm.png"/>-->
          <img :src="qrcode"/>
          <div class="ewm-text"> 扫码关注<br/>微信公众号</div>
        </div>
      </div>
      <div class="banquan">版权所有 备案号： {{ aboutUs.site_beian }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      list: [{
        name: '首页',
        url: '/',
      },
        {
          name: '医院概况',
          url: '/overview',
        },
        {
          name: '新闻动态',
          url: '/newList',
        },
        {
          name: '就医指南',
          url: '/pingmian',
        },
        {
          name: '科室介绍',
          url: '/daohang',
        },
       /* {
          name: '医护科教',
          url: '',
        },
        {
          name: '信息公开',
          url: '',
        },
        {
          name: '媒体集锦',
          url: '',
        },
        {
          name: '患者心声',
          url: '',
        }*/
        ],
      aboutUs: {},
      qrcode: '',
    }
  },
  created() {
    this.openList();
  },
  mounted() {
  },

  methods: {
    // 联系我们
    openList() {
      this.$apiFun.commonConfig().then(res => {
        this.aboutUs = {
          mobile: res.data.mobile,
          address: res.data.address,
          site_beian: res.data.site_beian,
        };
        this.qrcode = res.data.mp_pic;
      })
    },
    toInfo(url) {
      this.$router.push({
        path: url
      })
    },
  }
}
</script>

<style scoped>
.fotnav {
  width: 1200px;
  margin: auto;
}

.box {
  width: 100%;
  max-width: 1920px;
  margin: auto;
  //height: 367px;
  height: 269px;
  box-sizing: border-box;
  color: #fff;
  background: url('../assets/images/fy/foot1.png') no-repeat;
  background-size: 100% 100%;
  background-color: #FBF6FB;
}

.fotinfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //padding-top: 130px;
  padding-top: 90px;
}

.fotlist {
  display: flex;
  align-items: center;
  margin-bottom: 48px;
  justify-content: space-between;
  width: 800px;
}

.fotlist-n {
  margin-right: 42px;
}

.banquan {
  //margin-top: 90px;
  margin-top: 30px;
}

.ewm {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.ewm img {
  width: 108px;
  height: 108px;
  margin-right: 20px;
}

.ewm-text {
  text-align: center;
  line-height: 25px;
}
</style>
