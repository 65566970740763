//引入刚才的http.js文件
import https from './request.js';

let apiFun = {};

// 示例
apiFun.index = function (params) {
    return https.get('index/index', params)
}
//轮播图
apiFun.bannerList = function (params) {
    return https.get('index/bannerList', params)
}
//新闻公告列表
apiFun.messageNoticeList = function (params) {
    return https.get('index/messageNoticeList', params)
}
//新闻公告详情
apiFun.messageNoticeInfo = function (params) {
    return https.get('index/messageNoticeInfo', params)
}
//医院概况
apiFun.hospitalOverview = function (params) {
    return https.get('index/hospitalOverview', params)
}
//医院概况详情
apiFun.hospitalOverviewInfo = function (params) {
    return https.get('index/hospitalOverviewInfo', params)
}
//就医指南
apiFun.hospitalGuide = function (params) {
    return https.get('index/hospitalGuide', params)
}
//就医指南详情
apiFun.hospitalGuideInfo = function (params) {
    return https.get('index/hospitalGuideInfo', params)
}
//科室介绍
apiFun.departmentIntro = function (params) {
    return https.get('index/departmentIntro', params)
}
//科室介绍详情
apiFun.departmentIntroInfo = function (params) {
    return https.get('index/departmentIntroInfo', params)
}
//信息公开列表
apiFun.openList = function (params) {
    return https.get('index/openList', params)
}
//院务公开详情
apiFun.openInfo = function (params) {
    return https.get('index/openInfo', params)
}
//基础配置
apiFun.commonConfig = function (params) {
    return https.get('index/commonConfig', params)
}

//暴露出这个对象
export default apiFun;

