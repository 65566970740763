import {
	createRouter,
	createWebHistory,
	createWebHashHistory
} from 'vue-router'
import header from '../components/header.vue' //头部
import footer from '../components/footer.vue' //底部

const routes = [{ //首页
	path: '/',
	name: 'home',
	component: () => import('../views/home.vue')
}, { //关于我们
	path: '/about',
	name: 'about',
	component: () => import('../views/about/about.vue')
}, { //院务公开
	path: '/yuanwu',
	name: 'yuanwu',
	component: () => import('../views/about/yuanwu.vue')
}, { //院务公开-详情
	path: '/yuanwuInfo',
	name: 'yuanwuInfo',
	component: () => import('../views/about/yuanwuInfo.vue')
}, { //医院简介
	path: '/introduce',
	name: 'introduce',
	component: () => import('../views/hospitalOverview/introduce.vue')
}, { //历史沿革
	path: '/history',
	name: 'history',
	component: () => import('../views/hospitalOverview/history.vue')
}, { //医院文化
	path: '/culture',
	name: 'culture',
	component: () => import('../views/hospitalOverview/culture.vue')
}, { //医院荣誉
	path: '/glory',
	name: 'glory',
	component: () => import('../views/hospitalOverview/glory.vue')
}, { //院区展示
	path: '/show',
	name: 'show',
	component: () => import('../views/hospitalOverview/show.vue')
}, { //先进设备
	path: '/device',
	name: 'device',
	component: () => import('../views/hospitalOverview/device.vue')
}, {// 医院概况
	path: '/overview',
	name: 'overview',
	component: () => import('../views/hospitalOverview/overview.vue')
}, {// 医院概况
	path: '/overviewInfo',
	name: 'overviewInfo',
	component: () => import('../views/hospitalOverview/overviewInfo.vue')
}, { //医院新闻
	path: '/newList',
	name: 'newList',
	component: () => import('../views/news/newList.vue')
}, { //医院新闻-详情
	path: '/newInfo',
	name: 'newInfo',
	component: () => import('../views/news/newInfo.vue')
}, { //通知公告
	path: '/noticeList',
	name: 'noticeList',
	component: () => import('../views/news/noticeList.vue')
}, { //通知公告-详情
	path: '/noticeInfo',
	name: 'noticeInfo',
	component: () => import('../views/news/noticeInfo.vue')
}, { //院平面图
	path: '/pingmian',
	name: 'pingmian',
	component: () => import('../views/guide/pingmian.vue')
}, { //预约挂号
	path: '/yuyue',
	name: 'yuyue',
	component: () => import('../views/guide/yuyue.vue')
}, { //就医须知
	path: '/jiuyi',
	name: 'jiuyi',
	component: () => import('../views/guide/jiuyi.vue')
}, { //就医须知-详情
	path: '/jiuyiInfo',
	name: 'jiuyiInfo',
	component: () => import('../views/guide/jiuyiInfo.vue')
}, { //医保指南
	path: '/yibao',
	name: 'yibao',
	component: () => import('../views/guide/yibao.vue')
}, { //医保指南-详情
	path: '/yibaoInfo',
	name: 'yibaoInfo',
	component: () => import('../views/guide/yibaoInfo.vue')
}, { //科室导航
	path: '/daohang',
	name: 'daohang',
	component: () => import('../views/keshi/daohang.vue')
}, { //科室导航-详情
	path: '/daohangInfo',
	name: 'daohangInfo',
	component: () => import('../views/keshi/daohangInfo.vue')
}, { //重点学科
	path: '/kexue',
	name: 'kexue',
	component: () => import('../views/keshi/kexue.vue')
}, { //重点学科-详情
	path: '/kexueInfo',
	name: 'kexueInfo',
	component: () => import('../views/keshi/kexueInfo.vue')
}]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})
router.beforeEach((to, from, next) => {
	if (to.path == "/") {
		next();
	} else {
		next();
	}
})

export default router
