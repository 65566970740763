export default{
    handlingGarbledCode(str) {
        const arrEntities = {
            'lt': '<',
            'gt': '>',
            'nbsp': ' ',
            'amp': '&',
            'quot': '"'
        };
        if (str) {
            return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) {
                return arrEntities[t];
            });
        } else {
            return '';
        }
    },
}
